import { Component, OnInit, ViewChild } from '@angular/core';
import { SubscriptionNewService } from './subscription-new.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { Appearance, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription-new.component.html',
  styleUrls: ['./subscription-new.component.scss'],
  providers: [SubscriptionNewService]
})
export class SubscriptionNewComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  customerID: any;
  PaymentMethodID: any;
  selectedMonth = 1;
  email: any;
  isActiveSubscription = false;
  isCancelSubscription = false;
  isFailedPayment = false;
  isActiveStripSubscription = false;
  subscriptionExpireDate:any;
  currentUser: any;
  errorMsg: any;
  subscriptionId: any;
  isSubscriptionInProcess = false;

  constructor(private spinnerService: Ng4LoadingSpinnerService, private stripeService: StripeService, private SubscriptionService: SubscriptionNewService, private httpClient: HttpClient) {
    this.spinnerService.show();
    this.currentUser = JSON.parse(localStorage.getItem('loggedInUser'));
  }

  ngOnInit() {    
    this.getSubscriptionDetails();
  }

  getSubscriptionDetails() {
    var loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

    this.SubscriptionService.getSubscribe(parseInt(this.currentUser.id)).subscribe((res) => {
      var subscriptionEndDate = new Date(res.subscriptionEndDate);
      var CurrentDate = new Date();

      if(res.subscriptionStatus == 2 || subscriptionEndDate >= CurrentDate) {
        this.isActive(true);
        if(res.subscriptionStatus == 1) {
          this.isCancel(true);
        }
        
        if(res.subscription_id) {
          this.isActiveStripe(true);
        }
      } else {
        this.isActive(false);
      }
           
      this.customerID = res.stripe_customer_id;
      this.subscriptionExpireDate = res.subscriptionEndDate;
      this.subscriptionId = res.subscription_id;
      
      loggedInUser.subscriptionEndDate = res.subscriptionEndDate;
      localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));

      setTimeout(() => {
        this.spinnerService.hide();
      }, 2000);
      
    });
  }

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#000',
        backgroundColor: 'transparent',
         color: '#000',
         fontWeight: '300',
         fontSize: '18px',
         lineHeight:'40px',
         padding:'100px',
        '::placeholder': {
          color: '#000',
        },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      },
    }
  };

  elementsOptions: StripeElementsOptions = {
      locale: 'en',
  };

  subscriptionMonth(event) {
    this.selectedMonth = event.target.value;
    this.email = this.currentUser.email;
  }

  loadSubscription() {
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: { name: this.currentUser.name, email: this.currentUser.email },
    }).subscribe((result) => {
        if (result.paymentMethod) {
          const pack = {
            paymentMethodId: result.paymentMethod,
            customerID: this.customerID,
            cust_name: this.currentUser.username,
            cust_email: this.currentUser.email,
            userId: this.currentUser.id
          };

          this.SubscriptionService.createPaymentMethodForCustomer(pack).subscribe((response) => {

            this.customerID = response.customerID
            const pack = {
              paymentMethodId: result.paymentMethod,
              customerID: this.customerID,
              cust_name: this.currentUser.username,
              cust_email: this.currentUser.email,
              userId: this.currentUser.id
            };

            this.SubscriptionService.startSubscription(pack).subscribe((res) => {     
              this.customerID = res.customerId
              let clientSecret = res.clientSecret;
              if (res.status !=2 && clientSecret) {
                this.confirmCardPayment(clientSecret, res.subscriptionId);
              } else {
                  this.getSubscriptionDetails();
              }

            });
            
          });
          
        }   else if (result.error) {
          // Error creating the token
          this.isSubscriptionInProcess = false;
          this.spinnerService.hide();
          this.errorMsg = result.error.message;
          console.log(result.error.message)
        }
      }
    );

  }

  confirmCardPayment(clientSecret: string, subscriptionId) {
    this.stripeService.confirmCardPayment(clientSecret).subscribe((result: any) => {
      if (result.error) {
        console.error('Payment failed:', result.error);
        this.isFailedPayment = true;
        this.paymentFailed(subscriptionId);        
      } else if (result.paymentIntent.status === 'succeeded') {
        this.getSubscriptionDetails();
        console.log('Payment succeeded!');
      }
    });
  }

  paymentFailed(subscriptionId) {
 
    this.SubscriptionService.handlePaymentFailed(subscriptionId, this.currentUser.id).subscribe((res) => {
        this.getSubscriptionDetails();
    });
  }

  createToken(): void {
    this.errorMsg = "";
    this.isSubscriptionInProcess = true;
    this.spinnerService.show();
    this.loadSubscription()
    
  }

  cancelSubscription() {
    if(this.subscriptionId) {
      this.spinnerService.show();
      this.SubscriptionService.cancelSubscription(this.subscriptionId, this.currentUser.id).subscribe(
        (res: any) => {

          console.log('Subscription canceled:', res);
          
          var loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
          
          var subscriptionEndDate = new Date(res.subscriptionEndDate);
          var CurrentDate = new Date();
    
          if(res.subscriptionStatus == 2 || subscriptionEndDate >= CurrentDate) {
            this.isActive(true);
            if(res.subscriptionStatus == 1) {
              this.isCancel(true);
            }
            if(res.subscription_id) {
              this.isActiveStripe(true);
            }
          } else {
            this.isActive(false);
          }
               
          this.customerID = res.stripe_customer_id;
          this.subscriptionExpireDate = res.subscriptionEndDate;
          this.subscriptionId = res.subscription_id;
          
          loggedInUser.subscriptionEndDate = res.subscriptionEndDate;
          localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
    
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
          console.error('Error canceling subscription:', error);
        }
      );

    }
  }

  isActive( boolIsActive ){
    this.isActiveSubscription = boolIsActive;
  }

  isCancel( boolIsCancel ) {
    this.isCancelSubscription = boolIsCancel;
  }

  isFailed( boolIsFailed ) {
    this.isFailedPayment = boolIsFailed;
  }

  isActiveStripe( boolIsActive ) {
    this.isActiveStripSubscription = boolIsActive;
  }
}
